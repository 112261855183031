<template>
  <div class="welcome-container">
    <v-img :src="`${backgroundImage}`"></v-img>
  </div>
</template>
<script>
import backgroundImage from '@/assets/frameHome.png';
export default {
  name: 'Home',
  components: {},
  data() {
    return { backgroundImage };
  },
};
</script>
<style scoped lang="scss">
.welcome-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (max-width: 1365px) {
  .welcome-container {
    div {
      min-width: 1238px;
    }
  }
}
@media (max-width: 1920px) {
  .welcome-container {
    div {
      max-width: 1238px;
    }
  }
}

@media (min-width: 1920px) {
  .welcome-container {
    div {
      max-width: 1792px;
    }
  }
}

</style>
